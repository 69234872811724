import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    booking: {
      property: "",
      roomType: "",
      arrivalDate: "",
      departureDate: "",
      name: "",
      email: "",
      phone: "",
      numberOfRooms: "",
      holidayType: "",
      adults: "",
      children: "",
      nationality: "",
      requirements: "",
      addOns: [],
      specialOffer: {
        name: "",
        percentage: "",
      },
    },
  },
  mutations: {
    SET_BOOKING_FIELD(state, { field, value }) {
      state.booking[field] = value;
    },
    ADD_SPECIAL_OFFER(state, offer) {
      state.booking.specialOffer.name = offer.name;
      if (offer.percentage) {
        state.booking.specialOffer.percentage = offer.percentage;
      }
    },
    RESET_BOOKING_DATA(state) {
      state.booking = {
        property: "",
        roomType: "",
        arrivalDate: "",
        departureDate: "",
        name: "",
        email: "",
        phone: "",
        numberOfRooms: "",
        holidayType: "",
        adults: "",
        children: "",
        nationality: "",
        requirements: "",
        addOns: [],
        specialOffer: {
          name: "",
          percentage: "",
        },
      };
    },
  },
  modules: {},
});

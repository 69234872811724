import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: () => import("../components/pages/Home.Page")
  },
  {
    path: "/accommodations/:slug",
    name: "ResortPage",
    component: () => import("../components/pages/Resort.Page")
  },
  {
    path: "/accommodations",
    name: "AccommodationsPage",
    component: () => import("../components/pages/Accommodations.Page")
  },
  {
    path: "/agents",
    name: "AgentsPage",
    component: () => import("../components/pages/Agents.Page")
  },
  {
    path: "/affiliates",
    name: "AffiliatesPage",
    component: () => import("../components/pages/Affiliate.Page")
  },
  {
    path: "/contact",
    name: "ContactPage",
    component: () => import("../components/pages/Contact.Page")
  },
  {
    path: "/booking",
    name: "BookingPage",
    component: () => import("../components/pages/Booking.Page")
  },
  {
    path: "/package-booking",
    name: "PackageBookingPage",
    component: () => import("../components/pages/PackageBooking.Page")
  },
  {
    path: "/packages",
    name: "PackagesListPage",
    component: () => import("../components/pages/PackagesList.Page")
  },
  {
    path: "/packages/:id",
    name: "PackagesPage",
    component: () => import("../components/pages/Packages.Page")
  },
  {
    path: "/offers",
    name: "OffersPage",
    component: () => import("../components/pages/Offers.Page")
  },
  {
    path: "/about",
    name: "AboutPage",
    component: () => import("../components/pages/About.Page")
  },
  {
    path: "/policies",
    name: "PoliciesPage",
    component: () => import("../components/pages/Policies.Page")
  },
  {
    path: "/culture",
    name: "CulturePage",
    component: () => import("../components/pages/Culture.Page")
  },
  {
    path: "/guidelines",
    name: "GuidelinesPage",
    component: () => import("../components/pages/Guidelines.Page")
  },
  {
    path: "/covidupdates",
    name: "CovidPage",
    component: () => import("../components/pages/Covid.Page")
  },
  {
    path: "/maldivesvisa",
    name: "VisaPage",
    component: () => import("../components/pages/Visa.Page")
  },
  {
    path: "/pages",
    name: "PagesPage",
    component: () => import("../components/pages/Pages.Page"),
    beforeEnter(to, from, next) {
      redirectIfUnauthorized(next);
    }
  },
  {
    path: "/error",
    name: "ErrorPage",
    component: () => import("../components/pages/Error.Page"),
    beforeEnter(to, from, next) {
      if (store.state.auth.token) {
        next("/error");
      } else {
        next();
      }
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "sidebar__item-active",
  routes
});

const redirectIfUnauthorized = next => {
  if (store.state.auth.token) {
    next();
  } else {
    next("/");
  }
};

export default router;
